import React from "react";
import Layout from "../components/layout";
import WhiteCard from "../components/white-card";
import { GiWifiRouter } from "react-icons/gi";
import { MdOutlineContentCopy } from "react-icons/md";
import StarlinkImage from "../images/starlink-setup.webp";

const h1ClassNames = "text-center font-bold text-4xl pb-5";
const h2ClassNames = "italic font-bold text-3xl pt-4";
const pClassNames = "pt-4 pl-2 text-xl";
const olClassNames = "list-decimal pl-4";
const liClassNames = "pt-2 text-xl";

export function Head() {
  return (
    <>
      <title>Overlook Chalet</title>
      <meta name="description" content="Overlook Chalet" />
    </>
  );
}

const TroubleShooting = () => {
  async function handleCopyName() {
    return await navigator.clipboard.writeText("OverlookChalet");
  }
  async function handleCopyPassword() {
    return await navigator.clipboard.writeText("Hunter91!");
  }

  return (
    <Layout>
      <WhiteCard>
        <section>
          <h1 className={h1ClassNames}>Troubleshooting</h1>
          <p className={pClassNames}>
            We're sorry something has gone wrong. Let's try to resolve this as
            quickly as possible.
          </p>
          <p className={pClassNames}>
            As always feel free to contact Craig or Chelsea - Craig&nbsp;
            <a className="text-blue-800 font-bold" href="tel:5186103492">
              (518-610-3492)&nbsp;
            </a>
            or Chelsea&nbsp;
            <a className="text-blue-800 font-bold" href="tel:5182606820">
              (518-260-6820)
            </a>
            , with urgent requests or emergencies.
          </p>

          <h2 id="houseaccess" className={h2ClassNames}>
            Wifi Troubleshooting:
            <GiWifiRouter className="m-auto text-3xl" />
          </h2>

          <p className={pClassNames}>
            If the <b>OverlookChalet</b> network is not visible on your network
            list, please do the following.
          </p>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              Power cycle the white router (to the right of the fireplace) three
              times, by unplugging and plugging it back in. The router will take
              about 3 minutes to boot back up.
            </li>

            <li className={liClassNames}>
              Open your WiFi settings using your mobile device or laptop and
              connect to the network starting with <em>Starlink-</em> or{" "}
              <em>Stinky</em> (thanks, Elon. Cool joke.)
            </li>
            <li className={liClassNames}>
              A wizard will pop up as shown below
              <img src={StarlinkImage} alt="starlink-setup" />
            </li>
            <li className={liClassNames}>
              Fill in the following information. It is important that these
              details are accurate, as the smart devices in the house will only
              connect if they match the below.
              <ul>
                <li>
                  Name: <tt>OverlookChalet&nbsp;</tt>
                  <button onClick={handleCopyName}>
                    <MdOutlineContentCopy className="inline" />
                  </button>
                </li>
                <li>
                  Password: <tt>Hunter91!&nbsp;</tt>
                  <button onClick={handleCopyPassword}>
                    <MdOutlineContentCopy className="inline" />
                  </button>
                </li>
              </ul>
            </li>
            <li className={liClassNames}>Click 'Create'</li>
          </ol>
          <p className={pClassNames}>
            At this point, the network will be created (appoximately a 3-5
            minute process) and the TV will automatically connect. You will need
            to connect your device using the new network.
          </p>
        </section>
      </WhiteCard>
    </Layout>
  );
};
export default TroubleShooting;
